.UploadVehicleDocument {

  h6 {
    font-size: 18px !important;
    line-height: 22px !important;
  }

  input {
    display: none;
  }
}