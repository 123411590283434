.ListComponent {
  &.MuiList-root {
    padding-bottom: 0;

    .MuiListItem-root {
      padding: 10px 10px;

      .MuiListItemAvatar-root {
        text-align: center;
        margin: 5px auto 0;

        span,
        svg {
          font-size: 32px;
          color: var(--gray-theme);
        }
      }

      .MuiListItemText-root {
        margin: 0;

        span {
          color: var(--gray-theme);
          font-size: 14px;
          line-height: 20px;
          word-break: break-word;
        }
      }
    }
  }
}