.IdentificationDocument {
  h4 {
    font-size: 26px;
    line-height: 28px;
    font-weight: 600;
  }

  .img-principal {
    width: 180px;
    margin-top: 15px;
  }

  p {
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
  }
}
