.Welcome {
  //background-color: var(--primary);
  text-align: center;
  height: 100vh;
  padding: 0 25px;

  .app-logo {
    width: 200px;
  }

  .logo-store {
    width: 180px;
  }

  h6 {
    color: var(--secondary);
    font-weight: 300;
  }

  p {
    color: var(--secondary);
    font-size: 22px;
    line-height: 24px;
  }
}