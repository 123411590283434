.NoticeInformation {
  .icon {
    font-size: 80px;
    color: var(--gray-theme);
  }

  h6 {
    font-size: 18px;
    color: var(--gray-theme);
    line-height: 22px;
  }

  p {
    font-size: 14px;
    color: var(--gray-theme);
    line-height: 18px;
    text-align: justify;
  }

  label {
    span {
      font-size: 14px;
      color: var(--gray-theme);
      line-height: 16px;
      text-align: left;
    }
  }

  a:visited {
    color: #0650c7;
  }
}