.Permissions {
  .content-body {
    .icon {
      font-size: 80px;
      color: var(--gray-theme);
    }

    h4 {
      font-size: 22px;
      line-height: 24px;
      color: var(--gray-theme);
      font-weight: 600;
      margin-top: 15px;
    }

    img {
      width: 160px;
    }

    .MuiList-root {
      margin-top: 20px;
      padding-bottom: 0;

      .MuiListItem-root {
        padding: 10px 20px;

        .MuiListItemAvatar-root {
          text-align: center;
          margin: 5px auto 0;

          svg {
            font-size: 32px;
            color: var(--gray-theme);
          }
        }

        .MuiListItemText-root {
          margin: 0;

          span {
            color: var(--gray-theme);
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
