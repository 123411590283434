.Instruction {
  .content-body {
    padding: 0 15px;

    h4 {
      font-size: 20px;
      line-height: 22px;
      color: var(--gray-theme);
      font-weight: 600;
    }

    h6 {
      font-size: 20px;
      line-height: 22px;
      color: var(--gray-theme);
      font-weight: 400;
    }

    img {
      width: 160px;
      margin-top: 10px;
    }
  }

  .img-box {
    background: linear-gradient(to right,
        var(--gray-theme) 4px,
        transparent 4px) 0 0,
      linear-gradient(to right, var(--gray-theme) 4px, transparent 4px) 0 100%,
      linear-gradient(to left, var(--gray-theme) 4px, transparent 4px) 100% 0,
      linear-gradient(to left, var(--gray-theme) 4px, transparent 4px) 100% 100%,
      linear-gradient(to bottom, var(--gray-theme) 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, var(--gray-theme) 4px, transparent 4px) 100% 0,
      linear-gradient(to top, var(--gray-theme) 4px, transparent 4px) 0 100%,
      linear-gradient(to top, var(--gray-theme) 4px, transparent 4px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding: 20px 10px;

    &.preview {
      padding: 20px;
    }

    img {
      margin: 0;
      width: 100%;
    }
  }
}