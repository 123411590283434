.NoticeDebts {
    .icon {
        font-size: 80px;
        color: var(--gray-theme);
    }

    h6 {
        font-size: 18px;
        color: var(--gray-theme);
        line-height: 22px;
    }

    p {
        font-size: 14px;
        color: var(--gray-theme);
        line-height: 18px;
        text-align: justify;
    }

    label {
        margin-right: 50px;

        &:last-child {
            margin-right: 0;
        }
        
        .MuiFormControlLabel-label {
            font-size: 14px;
            color: var(--gray-theme);
            line-height: 16px;
            text-align: left;
        }
    }
}