.NoticeLocation {
    .icon {
        font-size: 80px;
        color: var(--gray-theme);
    }

    h6 {
        font-size: 18px;
        color: var(--gray-theme);
        line-height: 22px;
    }

    p {
        font-size: 14px;
        color: var(--gray-theme);
        line-height: 18px;
        margin-top: 10px;
    }
}