.PhoneValidation {
  .content-body {
    h6 {
      font-size: 16px;
      line-height: 22px;
      color: var(--gray-theme);
      font-weight: 400;
    }

    .codeSMS {
      margin: 20px auto;
    }

    .ReactInputVerificationCode__item {
      width: 4rem;
      color: var(--primary);
    }
  }
}