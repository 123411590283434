.VideoComponent {
  .overlay-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .camera {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    margin-left: -50%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }

  .bottom-pos {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    z-index: 9;
  }

  .script {
    background: #fff;
    max-height: 70px;
    overflow-y: auto;

    p {
      font-size: 24px;
      line-height: 30px;
      margin: 10px;
    }
  }

  .btn-rec {
    margin-top: 15px;
    border-radius: 11px;
    background-color: #d72626;
    padding: 7px 10px;
    border: 3px solid #d72626;
    color: white;
    font-size: 22px;
    font-weight: bold;
    opacity: 1;
  }

  .letter-space {
    letter-spacing: 15px;
  }
}
