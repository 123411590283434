.Timer {
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;

  .digits {
    font-size: 48px;
    color: #f5f5f5;
  }
}
