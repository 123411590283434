.Checklist {
  button {
    &.button-app {
      background-color: var(--primary);
      color: var(--secondary);
      padding: 12px 35px;
      border-radius: 30px;
      font-size: 14px;
      font-style: inherit;
      text-decoration: none;
      font-weight: 600;
      outline: none;

      &:disabled {
        background-color: var(--primary-opacity) !important;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
