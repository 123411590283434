.ChecklistItens {
  [role='separator']:last-child {
    display: none !important;
  }

  .MuiDivider-root {
    margin-left: 0;
  }

  .MuiListItem-root {
    padding: 5px 0;

    .MuiListItemText-root {
      span {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 3px;
      }

      p {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  h5 {
    background-color: #f5f5f5;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    line-height: 38px;
    margin: 0;
    font-weight: 500;

    &.success {
      background-color: var(--success) !important;
    }

    svg {
      width: 24px;
      height: 24px;
      margin: 8px 0;
      color: var(--secondary);
    }
  }

  button {
    &.btn-link {
      width: 100%;
      color: var(--gray-theme);
      text-align: left;
      text-decoration: none;
      font-style: normal;
      text-transform: none;

      &:disabled {
        background-color: rgba(0, 0, 0, 0.01);

        .MuiListItemText-root {
          text-decoration: line-through;
          opacity: 0.5;
        }
      }

      .MuiButton-root {
        padding: 5px !important;
        font-size: 12px !important;
        margin: 0 0 0 10px !important;
      }
    }
  }
}
