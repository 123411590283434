.UpdateBankInfo {
    .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border-color: var(--primary) !important;
        }
    }

    .css-gei84d-MuiFormLabel-root-MuiInputLabel-root {
        color: var(--primary) !important;
    }

    .bg-box {
        background-color: #f5f5f5;
        color: #E60000;
    }
}