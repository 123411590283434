.ContractReview {
  .content-body {
    padding: 0 15px;
    margin-top: -70px;

    h4 {
      font-size: 16px;
      line-height: 18px;
      color: var(--gray-theme);
      font-weight: 600;

      span {
        font-size: 18px;
      }
    }

    h6 {
      font-size: 20px;
      line-height: 22px;
      color: var(--gray-theme);
      font-weight: 400;
    }

    p {
      font-size: 14px;
      line-height: 15px;
      color: var(--gray-theme);
    }

    .box-bg {
      background-color: #f5f5f5;
      border-radius: 5px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
      text-align: left;

      .MuiTypography-body1 {
        font-size: 13px;
        line-height: 10px;
        color: var(--primary);
      }

      .MuiTypography-body2 {
        font-size: 17px;
        line-height: 20px;
        color: var(--gray-theme);
        font-weight: 900;
        margin-top: 5px;

        span {
          font-size: 16px;
        }
      }
    }
  }

  .MasterPage {
    background-color: aqua;
  }
}