.CameraComponent {
  .overlay-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .camera {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    margin-left: -50%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }
}
